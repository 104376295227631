import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import Hero from 'src/pages/trade-marketing-orlando-city/sections/hero/_index'
import { Wrapper } from 'src/pages/nycfc-match/style'

import pageContext from './pageContext.json'

const OrlandoCity = () => {
  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default OrlandoCity
